/* Loading.css */
.loading-enter {
    transform: translateX(-100%);
    opacity: 0;
}
.loading-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 700ms ease-in, opacity 700ms ease-in;
}
.loading-enter-done {
    transform: translateX(0);
    opacity: 1;
}
.loading-exit {
    transform: translateX(0);
    opacity: 1;
}
.loading-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 700ms ease-in, opacity 700ms ease-in;
}
.loading-exit-done {
    transform: translateX(100%);
    opacity: 0;
}
